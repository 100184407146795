import React, { Fragment } from 'react'
import { useSelector } from "react-redux";
import ClubCardNew from './ClubCardNew'
// import { Grid, Typography } from "@material-ui/core";
import { Translate } from 'utils/Translate';
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Routes from "components/Router/Routes"
import Workout from 'hoc/WorkoutContainer';
import IconRigntArrow from 'CommonImages/icon-rightArrow';
import { useNavigate } from 'react-router';
const config = require("assets/config.json")


function ClubPage(props) {
    const navigate = useNavigate();
    const isAuthenticated = useSelector(state => state.auth.token !== "")
    const viewDetails = () => {
        navigate( Routes.connectOndemandClasses,
            { state: { clubClasses: props.clubClasses } } );
    };

    const onClickHandler = data => {
        navigate( Routes.onDemandClassPlayerRoute + data.tag,
            { state: { selectedItem: data } } );
    };
    return (
        <Fragment>
            <div className="m-t-30">
            <div className='m-b-32 m-b-15'>
                    <div className="align-left d-flex makeTextClickable m-b-7" onClick={viewDetails}>
                        <h1 className="align-left font-h1 ">{Translate({ id: "homePage.ConnectTitle" })} </h1>
                        <IconRigntArrow id='arrow-connect' color={config.primaryColor} />
                    </div>
                    <h6 className="text-gray font-h6 align-left "  >
                        {Translate({ id: "homePage.ConnectDescription" })}
                    </h6>
                </div>
            </div>
            <div className="m-t-xs-15 mobileScroll">
                <div container justify="flex-start" spacing={4} className="cardList list flexNoWrap livstreamHome">
                    {props.clubClasses.map((item, index) => (
                        index < 5 ?
                            // <Grid item lg={4} md={6} sm={12} xs={12} key={item.id}>
                            <div>

                                {config.connectSource === 'wexer' ?
                                    <Workout
                                        //keywords={item.keywords}
                                        cardType="channel-page-card"
                                        collectionType="live"
                                        isSearchPageCard={false}
                                        displayDate={item.virtualClass?.scheduleDate}
                                        trailerLinkWeb={item.virtualClass?.trailerLinkWeb}
                                        trailerLinkMobile={item.virtualClass?.trailerLinkMobile}
                                        isSliderWorkout={true}
                                        history={props.history}
                                        showFav={isAuthenticated ? true : false}
                                        thumbnail={`${item.virtualClass?.imageLink}`}
                                        equipmentTypes={item.virtualClass?.equipmentTypes}
                                        equipmentNames={item.virtualClass?.equipmentNames}
                                        isFav={item.virtualClass?.favourite}
                                        title={item.virtualClass?.className}
                                        duration={item.virtualClass?.durationSecond
                                            ? Math.ceil(item.virtualClass?.durationSecond / 60)
                                            : 55}
                                        description={item.virtualClass?.classDescription}
                                        show={true}
                                        calories={item.virtualClass?.calorieBurn}
                                        id={item.virtualClass?.tag}
                                        classCategory={item.virtualClass?.classCategory}
                                        mediaType={item.virtualClass?.mediaType}
                                        // key={categoryId + index}
                                        clicked={() => onClickHandler(item.virtualClass)}
                                        level={item.virtualClass?.level ? item.virtualClass.level : item.level }
                                        // collectionName={categoryId}
                                        // favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                                    /> :
                                    <ClubCardNew clubData={item}></ClubCardNew>
                                }

                            </div>
                            : null
                    ))}
                </div>
            </div>
        </Fragment>
    )
}

export default ClubPage

