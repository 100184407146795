import ClasslistView from "../containers/PlayerAndCorousel/views/ClasslistView/ClasslistView";
import {
  fetchAllChannels,
  fetchAllClassesForChannel,
} from "../store/actions/channels";
import {
  setClassesSearchData,
  setPaginatedData,
  resetFilters,
} from "../store/actions/filter";
import { connect } from "react-redux";
import {
  fetchOnDemandClasses,
  clearClassData,
  FetchClubLiveConnect,
  FetchLSConnectHome,
  fetchCotdHomePageData,
  resetBrowseClassData,
  resetFllOndemand,
  collectionListClasses,
} from "../store/actions/ondemand";
// import { SendMarketingStatusFromMail, SendToastMsg } from "../store/actions/auth";
import { GetUserSubscription } from "../store/actions/subscription";
import { getUserActiveSubscription } from "../store/selectors/subscription";
import { injectIntl } from "react-intl";
import config from "../assets/config";

const mapStateToProps = (state) => {
  return {
    // collection: state.onDemand.onDemandCollection,
    collection: state.onDemand.collectionListClasses,
    selectedOnDemandClass: state.onDemand.selectedOnDemandClass,
    classes: [],
    clubClasses: config.showLiveEvent
      ? state.onDemand.LSHomeClasses
      : state.onDemand.clubClasses,
    loading: state.onDemand.loading,
    liveConnectLoading: state.onDemand.liveConnectLoading,
    isAuthenticated: state.auth.token !== "",
    isNewUser: state.auth.userCreated,
    userActiveSubscription: getUserActiveSubscription(state),
    isSubscriptionLoading: state.subscription.isSubscriptionLoading,
    userSubscription: state.subscription.userSubscripton,
    fetchUserDetailsLoading: state.auth.fetchUserDetailsLoading,
    allChannels: state.channels.allChannels,
    cotdHomePageData: state.onDemand.cotdHomePageData,
    fllOnDemand: state.onDemand.fllOnDemand,
    cotdLoading: state.onDemand.cotdLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    collectionListClasses: (data) => {
      dispatch(collectionListClasses(data));
    },
    // onOnDemandClassesLoad: (userId) => dispatch(fetchOnDemandClasses(userId)),
    onCotdHomePageData: (userId) => dispatch(fetchCotdHomePageData(userId)),
    clearClassData: () => dispatch(clearClassData()),
    fetchClubLiveConnectClasses: () => dispatch(FetchClubLiveConnect()),
    loadUserSubscription: () => dispatch(GetUserSubscription()),
    FetchLSConnectHome: () => dispatch(FetchLSConnectHome()),
    fetchAllChannels: () => dispatch(fetchAllChannels()),
    fetchAllClassesForChannel: (tag) =>
      dispatch(fetchAllClassesForChannel(tag)),
    resetBrowseClassData: () => dispatch(resetBrowseClassData()),
    setClassesSearchData: () => dispatch(setClassesSearchData([])),
    setPaginatedData: () => dispatch(setPaginatedData([])),
    resetFilters: () => dispatch(resetFilters()),
    resetFllOndemand: () => dispatch(resetFllOndemand()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ClasslistView));
