import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, Typography, Fade } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import Button from "@material-ui/core/Button";
import moment from "moment";
import CustomDialog from "../../components/Modal/Modal";
import CustomDialogFAC from "../../components/Modal/ModalFac";
import Spinner from "../../components/spinner/spinner";
import { getSubscriptionPlatformSource } from "utils/utilityfunctions";
import { platformSource } from "utils/constants";
const config = require("../../assets/config.json")

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      isFACDialogOpen: false,
      loading: true,
      couponCode: "",
      iscouponCodeFilled: false
    }
  }

  handleDialog = () => {
    this.setState({
      isDialogOpen: true,
    })
  }

   handleFACDialogClickEvent = (event) => {
    const dialog = document.getElementById('account-Fac')
    if (!(dialog.contains(event.target))) {
      console.log('clicked inside');
      this.handleFACDialogClose()
    } else {
      console.log('clicked outside');
    }
  }
  handleFACDialogOpen = () => {
    this.setState({
      isFACDialogOpen: true,
    })
    setTimeout(() => {
      document.body.addEventListener('click', 
        this.handleFACDialogClickEvent
      );
    }, 100);
  }
  handleFACDialogClose = () => {
    document.body.removeEventListener('click', this.handleFACDialogClickEvent)
    this.setState({
      isFACDialogOpen: false,
    })
  }
  /**
   * 06th June 2020
   * Nikhil Gupta
   * CF-7973
   * Enter Free access code from account page
   */
  renewSubscription = e => {
    e.preventDefault();
    this.props.onRenewSubscription(this.state.couponCode)
  }

  handleDialogClose = () => {
    this.setState(
      {
        isDialogOpen: false,
      }
    )
  }

  logoutUser = () => {
    this.props.onLogout();
    window.location.href = "/";
  };

  componentDidMount() {
    // if (this.props.userDetail === null) {
    this.props.loadUserDetail();
    !config.isThirdPartyEnabled && this.props.loadUserSubscriptionDetails();
    !config.isThirdPartyEnabled && this.props.GetSubscriptionPortalLink();
    // }
    !config.isThirdPartyEnabled && this.props.loadSubscriptionProducts();
    if (!this.props.isAuthenticated) {
      this.props.saveDeeplinkPayload(window.location.pathname)
      this.props.navigate("/signin", {replace: true} );
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.couponCode.length !== 0) {
      if (this.state.iscouponCodeFilled === false) {
          this.setState({
              iscouponCodeFilled: true,
          })
      }
    }
    else {
      if (this.state.iscouponCodeFilled === true) {
          this.setState({ iscouponCodeFilled: false })
      }
    }
    if (prevProps.userDetail !== this.props.userDetail) {
      this.setState({ loading: false})
    }
  }

  CancelUserSubscription = () => {
    this.props.cancelUserSubscription(
      this.props.userActiveSubscription.subscription.subscriptionTag
    );
  };
  //not working as if now
  ReactivateUserSubscription = () => {
    this.props.reactivateUserSubscription(
      this.props.userActiveSubscription.subscription.subscriptionTag,
      this.props.userActiveSubscription.plan.tag,
      this.props.userActiveSubscription.plan.productId
    );
  };

  changeEmailClickHandler = () => {
    this.props.navigate("/user/update",  {state: { updateItem: "email" }});
  };

  editProfileClickHandler = () => {
    this.props.navigate("/user/update", {state: { updateItem: "name" }});
  };

  CancelUserSubscription = () => {
    this.props.cancelUserSubscription();
  };

  subscriptionPortal = (message) => {
      return (<> <div className="subscription-detail">
      <Typography variant="h3" className="align-left text-lightgray">
      {Translate({
          id: `${message}`,
        })}
      </Typography>
      </div> </>)
    }

  checkTenantType = () => {
    if(config.isThirdPartyEnabled){
      return "others"
    } else {
      return "stripe"
    }

  }

  checkForStatusCode = (statusCode) => {
    if (statusCode === 404){
      return this.checkTenantType()
    }
  }

  render() {
    let subscriptionStatus = ''
    let subscriptionDetails = this.props.subscriptionDetails
    const platformSourceConstant = platformSource
    let platformSourceType = subscriptionDetails ? getSubscriptionPlatformSource(subscriptionDetails?.platformSource) : this.checkForStatusCode(this.props.subscriptionDetailStatusCode)
    let subscriptionDetailsLoading = this.props.subscriptionDetailsLoading;
    let showRenewButton = false;
    let showSubscriptionPortalButton = false;
    let showReActivatebutton = false;
    let showCancelbutton = false
    const en_moment = (date) => {
      const enMoment = moment(date);
      enMoment.locale("en");
      return enMoment;
    };

    const getPlatformMessage = (platformType) => {
      switch (platformType) {
        case "inapp":
          return "Subscription.InAppMessage"
          ;
        case "stripe":
          return subscriptionStatus
          ;
        case "others":
          return "Subscription.StripePortalMessageSSO"
          ;
      
        default:
          break;
      }
    }

    const date = (date) => {
      try {
        return date ? moment(date).format("DD.MM.YYYY") : "-";
      } catch (e) {
        return "-";
      }
    };

    if (this.props.subscriptionDetails != null) {
      if (this.props.subscriptionDetails.isTrial === true) {
        if (this.props.subscriptionDetails.cancelledAt != null) {
          if (this.props.subscriptionDetails.status) {
            subscriptionStatus = this.subscriptionPortal(
              "Subscription.StripePortalMessage"
            );
            showSubscriptionPortalButton = true;
          } else {
            subscriptionStatus = (
              <Typography variant="h3" className="align-left text-lightgray">
                {Translate({
                  id: "Subscription.NoActiveSubscriptionSub",
                })}
              </Typography>
            );
            showSubscriptionPortalButton = false;
            showRenewButton = true;
          }
        } else {
          subscriptionStatus = this.subscriptionPortal(
            "Subscription.StripePortalMessage"
          );
          showSubscriptionPortalButton = true;
        }
      } else if (this.props.subscriptionDetails.status === true) {
        if (this.props.subscriptionDetails.cancelledAt != null) {
          if (this.props.subscriptionDetails.status) {
            subscriptionStatus = this.subscriptionPortal(
              "Subscription.StripePortalMessage"
            );
            showSubscriptionPortalButton = true;
          } else {
            subscriptionStatus = (
              <Typography variant="h3" className="align-left text-lightgray">
                {Translate({
                  id: "Subscription.NoActiveSubscriptionSub",
                })}
              </Typography>
            );
            showSubscriptionPortalButton = false;
            showRenewButton = true;
          }
        }
        if (config.AccessCodeMandatory) {
          showCancelbutton = false;
          subscriptionStatus = (
            <Typography variant="h3" className="align-left text-lightgray">
              {Translate({
                id: "Subscription.FreeAccessCodeNoEndDate",
              })}
            </Typography>
          );
        } else {
          subscriptionStatus = this.subscriptionPortal(
            "Subscription.StripePortalMessage"
          );
          showSubscriptionPortalButton = true;
        }
      } else {
        subscriptionStatus = (
          <Typography variant="h3" className="align-left text-lightgray">
            {Translate({
              id: "Subscription.NoActiveSubscriptionSub",
            })}
          </Typography>
        );
        showSubscriptionPortalButton = false;
        showRenewButton = true;
        if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
          subscriptionStatus = (
            <Typography variant="h3" className="align-left text-lightgray">
              {Translate({
                id: "Subscription.NoActiveSubscription",
              })}
            </Typography>
          );
          showReActivatebutton = true;
          showRenewButton = false;
        }
      }
    } else if (this.props.isSubscriptionActive === false) {
      subscriptionStatus = (
        <Typography variant="h3" className="align-left text-lightgray">
          {Translate({
            id: "Subscription.NoActiveSubscriptionSub",
          })}
        </Typography>
      );
      showRenewButton = true;
      if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
        subscriptionStatus = (
          <Typography variant="h3" className="align-left text-lightgray">
            {Translate({
              id: "Subscription.NoActiveSubscription",
            })}
          </Typography>
        );
        showReActivatebutton = true;
        showRenewButton = false;
      }
    } else {
      if (config.AccessCodeMandatory && config.showReActivatebuttonForFAC) {
        showReActivatebutton = true;
      } else {
        subscriptionStatus = (
          <Typography variant="h3" className="align-left text-lightgray">
            {Translate({
              id: "Subscription.NoActiveSubscriptionSub",
            })}
          </Typography>
        );
        showSubscriptionPortalButton = false;
        showRenewButton = true;
      }
    }

    return (
      <div>
        <Fade
          in={this.props.loading}
          style={{
            transitionDelay: this.props.loading ? "800ms" : "0ms"
          }}
          unmountOnExit
        >
          <Spinner backColor="white" />
        </Fade>
        {/* <ProfileWrapper history={this.props.history} /> */}
          <div className="m-t-40 m-t-xs-5">
          <Typography variant="h1" className="review-page-title">
            {Translate({id: "UserAccount.Title"})}
            </Typography>
          </div>
        <Grid container spacing={0}>
          <div className="form-container account-form-margin m-t-xs-account">
            <Grid item>
              {/* <Grid item xs={12} sm={12}>
                <Typography variant="h1" className="m-b-20 m-t-5">
                  {Translate({ id: "UserAccount.Title" })}
                </Typography>
              </Grid> */}
              <Grid container direction="column" className="">

                <div className="alignleft clear">
                  <Grid container direction='row' justifyContent='space-between'>
                    <div >
                      <Typography variant="h2" className="subscribe-input">
                        {Translate({ id: "Profile.Title" })}
                      </Typography>
                    </div>
                    {config.showSignUpPage === false ? (<div></div>) : (
                      <Typography
                        variant='subtitle2'
                        color="secondary"
                        className="alignright valign-end button-link makeTextClickable"
                        onClick={this.editProfileClickHandler}
                      >
                        {Translate({ id: "Actions.Edit" })}
                      </Typography>)}
                  </Grid>
                </div>
                <hr className="hrow" />
              </Grid>

              <div className="">
                <Grid
                  className="mbottom-why57 align-left"
                  item
                  xs={12}
                  sm={12}
                >
                  <Grid container spacing={0} >

                    <Grid container className="">
                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.firstName
                          : ""}
                      </Typography>
                      <hr className="hrow" />

                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.lastName
                          : ""}
                      </Typography>
                      <hr className="hrow" />
                    </Grid>
                    <Grid
                      container className=""
                    >
                      <Typography variant="body1" className="subscribe-field">
                        {this.props.userDetail != null
                          ? this.props.userDetail.emailAddress
                          : ""}
                      </Typography>
                      {/* <hr className="hrow" />
                      <Typography variant="body1" className="subscribe-field password">
                        {this.props.userDetail && this.props.userDetail.password
                          ? this.props.userDetail.password
                          : "........."}
                      </Typography> */}
                      <hr className="hrow" />
                    </Grid>
                  </Grid>
                </Grid>
                <div>
                  <Grid >
                    <div className="makeTextClickable"  >
                      <Typography variant="h3" onClick={this.logoutUser} className="button-link m-t-15 m-t-xs-15 align-left" color='secondary'>
                        {Translate({ id: "layout.LogOut" })}
                      </Typography>
                    </div>
                    <div className="clear" />
                  </Grid>
                </div>
                {
                  <React.Fragment>
                    <Grid>
                      <div className="alignleft clear" >
                        <Typography variant="h2" className="subscribe-input m-t-subs m-t-xs-15">
                          {Translate({ id: "Subscription.Title" })}
                        </Typography>
                      </div>
                    </Grid>
                    <hr className="hrow" />
                    <Grid
                      className="subText m-t-15 m-t-xs-15"
                      item
                      xs={12}
                      sm={12}
                    >
                      {(subscriptionDetailsLoading || this.props.subscriptionDetailStatusCode === 500) ? <div></div> : platformSourceType !== platformSourceConstant.stripe ?
                          this.subscriptionPortal(getPlatformMessage(platformSourceType))
                          :
                          getPlatformMessage(platformSourceType) }
                    </Grid>
                  </React.Fragment>
                }
                {this.props.subscriptionDetailStatusCode === 500 ? <div></div> : <>
                {platformSourceType === platformSourceConstant.others &&
                  <Grid
                    className="subText m-t-5 m-t-xs-15"
                    item
                    xs={12}
                    sm={12}
                  >

                    <div className="makeTextClickable alignleft">
                      <Button className="button-link"
                        onClick={() => {
                          this.props.navigate({ pathname: "/contact" })
                        }}>
                        {<Typography variant="h3" color='secondary'>
                          {Translate({ id: "footer.contactUs" })}
                        </Typography>}
                      </Button>
                    </div>
                  </Grid>
                }
                {!subscriptionDetailsLoading && platformSourceType === platformSourceConstant.stripe && showSubscriptionPortalButton ? ( 
                    <Grid
                    className="subText m-t-5 m-t-xs-15"
                    item
                    xs={12}
                    sm={12}
                  >

                    <div className="makeTextClickable alignleft">
                      <a href={this.props.customerPortalUrl} target="_blank" className="customer-portal-link">
                        <Button className="button-link">
                        {<Typography variant="h3" color='secondary'>
                            {Translate({ id: "Subscription.ManageSubscription" })}
                        </Typography>}
                      </Button>
                      </a>
                    </div>
                  </Grid>
                  ):""
                  }
                <Grid container spacing={0} justifyContent='flex-start'>
                  {platformSourceType === "stripe"  && !subscriptionDetailsLoading && showRenewButton ? (
                    <Grid
                    className="subText m-t-5 m-t-xs-15"
                    item
                    xs={12}
                    sm={12}
                  >

                    <div className="makeTextClickable alignleft">
                      <Button className="button-link"
                        onClick={()=>{
                          this.props.navigate({ pathname: "/subscribe" })
                        }}>
                        {<Typography variant="h3" color='secondary'>
                          {Translate({ id: "Subscription.ChooseSubscription" })}
                        </Typography>}
                      </Button>
                    </div>
                  </Grid>
                  ):""
                  }
                </Grid>
                <Grid container spacing={0} justifyContent='flex-start'>
                  {config.AccessCodeMandatory && config.showReActivatebuttonForFAC && !subscriptionDetailsLoading && showReActivatebutton ? (

                    <Grid
                      className="subText m-t-5 m-t-xs-15"
                      item
                      xs={12}
                      sm={12}
                    >

                      <div
                        className="makeTextClickable alignleft"
                      >
                        <Button className="button-link"
                          onClick={this.handleFACDialogOpen}>
                          {
                            <Typography variant="h3" color='secondary'>
                              {Translate({ id: "UserAccount.EnterCodeButton" })}
                            </Typography>
                          }

                        </Button>
                      </div>
                      <CustomDialogFAC
                        open={this.state.isFACDialogOpen}
                        title={Translate({ id: "UserAccount.EnterCodeDialogHeading" })}
                        heading={Translate({ id: "UserAccount.EnterCodeDialogSubHeading" })}
                        cancelicon={false}
                        dialogId='account-Fac'
                        handleDialogClose={this.handleFACDialogClose}

                      ><Grid container direction="column" justifyContent="center"
                        style={{ width: "90%", maxWidth: "500px", margin: "auto" }}>

                          <p className="button-link" style={{ textAlign: "left" }}>
                            {<Typography variant="h3" style={{ color: "#fa2200" }}>
                              {this.props.accessCodeErrorMessage}
                            </Typography>}

                          </p>
                          <TextField
                            className=""
                            label={Translate({ id: "UserAccount.EnterCodeInputLabel" })}
                            value={this.state.couponCode}
                            onChange={e =>
                              this.setState({ couponCode: e.target.value })
                            }
                            variant="standard"
                            style={{ width: "100%", paddingBottom: "28px" }}
                          />
                          <Button
                            disabled={!this.state.iscouponCodeFilled}
                            onClick={this.renewSubscription}
                            variant="contained"
                            color="secondary"
                            className="facCodeEnterButton"
                          >
                            {<Typography variant="subtitle1">{Translate({ id: "UserAccount.GetAccess" })}</Typography>}
                          </Button>
                        </Grid>
                      </CustomDialogFAC>
                    </Grid>
                  ) : null}
                </Grid>
                </>
                }
              </div>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
}

export default Account
