//RR// Adding Routes constants 

const Routes = {
    homePage: "/home", //Home page || landing page || class list view
    classListViewRoute: "/classes", //old ondemand page 
    oldOnDemandClassPlayerRoute:"/ondemand/",
    connectClassPlayerRoute: "/connect/classes/",//+class ID 
    onDemandClassPlayerRoute: "/classes/", //+classID
    browseCollection: "/browse/",//+class ID
    channelsPlayer:"/channels/",
    collectionViewRoute: "/collections/",
    subscribeRoute:"/subscribe",
    searchRoute:"/classes/search",   
    oldSearchRoute:"/ondemand/search",
    favClassRoute:"/user/classes/favourites",
    reviewsRoute:"/user/reviews",
    recentActivityRoute:"/user/classes/recent",  
    collections:"/collections",     
    categories: "/classes/categories/",
    channelDetailView:"/channels/",
    connectClasses: "/connect/clubList",
    connect : "/connect/",
    connectOndemandClasses: "/connect/classes",
    connectLiveSchedule: "/connect/schedule",
    liveSchedulePlayerRoute: "/connect/live/",    
    signinPage:"/signin", 
    accountPage:"/user/account",
    
    //Add More Routes here.
     //v2 for backward compatibility of deeplinks
     collectionViewRouteV2: "/collection/",
     recentActivityRouteV2: "/classes/recent",
     favClassRouteV2: "/classes/fav",
     liveDetail: "/live/",
     userPlayList: "/user/playlist",
     playList:"/user/playlist/play" ,////+play list ID
     setPassword:"/setpassword"
}

export default Routes; 