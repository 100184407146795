import React, { useEffect, useState } from "react";
import { Translate } from "utils/Translate";
import Slider from "hoc/SliderContainer";
import Routes from "components/Router/Routes";
import config from "assets/config.json";
import ResCard from "CardVersions/version1/newcard";
import IconRigntArrow from "CommonImages/icon-rightArrow";
import { useNavigate } from "react-router";

function CollectionClasses(props) {
  const [collectionData, setcollectionData] = useState(props.collectionArray);
  const navigate = useNavigate();
  const cardVersion = config.cardVersion;
  useEffect(() => {
    /**
     * Nikhil Gupta
     * CF-9190
     * to show the featured collection under collection tag on home page
     */

    let collectionArray = props.collectionArray;
    if (collectionArray) {
      let featuredCollection = collectionArray.find((collection) => {
        return collection.collectionType.toLowerCase() === "featured";
      });
      setcollectionData(featuredCollection);
    }
  }, [props.collectionArray]);

  const clickHandler = () => {
    navigate(Routes.collections);
  };

  return collectionData &&
    collectionData.collectionItems &&
    collectionData.collectionItems.length > 0 ? (
    <>
      <div className="m-t-60 ">
        <div className="m-b-25 m-b-15">
          <div
            data-testid={"collectionTitleClick"}
            className="align-left d-flex makeTextClickable m-b-7"
            onClick={clickHandler}
          >
            <h1
              className="align-left font-h1 "
              data-testid="homePageCollectionTitle"
            >
              {Translate({ id: "homePage.CollectionsTitle" })}
            </h1>
            <IconRigntArrow id="arrow-1" color={config.primaryColor} />
          </div>
          <h6 className="text-gray font-h6 align-left ">
            {Translate({ id: "homePage.CollectionsDescription" })}
          </h6>
          <div
            className="align-left d-flex makeTextClickable m-t-12 collection-caption-cont"
            data-testid="collection-card"
            onClick={() => {
              navigate(
                Routes.collectionViewRoute + collectionData.collectionTag,
                {
                  state: {
                    selectedCollection: collectionData.collectionName,
                  },
                }
              );
            }}
          >
            <h6 className=" collection-caption align-left makeTextClickable ">
              {collectionData.collectionName}
            </h6>
            <IconRigntArrow id="arrow-2" color={config.primaryColor} />
          </div>
        </div>
      </div>
      {/* <Grid item className="mobileScrollParent ">
                <Grid container direction="row" alignItems="center" className="m-t-30 m-t-xs-32">
                    <Grid data-testid={"collectionTitleClick"} item className="align-left makeTextClickable adjustTitleWidth" onClick={clickHandler}>
                        <Typography variant="h1" className="align-left clear">
                            {Translate({ id: "homePage.CollectionsTitle" })}
                        </Typography>
                    </Grid>
                    <Grid item className="makeTextClickable" onClick={clickHandler}>
                        <KeyboardArrowRightIcon
                            className="align-bottom makeTextClickable iconColor"
                            style={{ fontSize: "2.7rem" }}
                            onClick={clickHandler}
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="h6" className="align-left text-gray">
                            {Translate({ id: "homePage.CollectionsDescription" })}
                        </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="align-left">
                        <Typography variant="caption" className="dynamiclinks makeTextClickable"
                            onClick={() => {
                                props.history.push({
                                    pathname: Routes.collectionViewRoute + collectionData.collectionTag,
                                    state: { selectedCollection: collectionData.collectionName },
                                })
                            }}>
                            {collectionData.collectionName}
                        </Typography>
                    </Grid>
                </Grid> */}
      <div className="CollectionHome mobileScroll m-t-8">
        {cardVersion === 0.1 && (
          <Slider
            key={Math.random()}
            collectionTag={collectionData.collectionTag}
            collectionName={collectionData.collectionName}
            items={collectionData.collectionItems}
            description={collectionData.description}
            listClass={"list"}
            fromHome={true}
            navigate={navigate}
          />
        )}
      </div>

      {/* </Grid> */}
      {cardVersion === 0.0 && (
        <ResCard
          collection={collectionData}
          cardType="live-channel-collection"
          history={props.history}
        />
      )}
    </>
  ) : null;
}

export default CollectionClasses;
