import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
import Spinner from "../../../../components/spinner/spinner";
import {
  StripComponent,
  ModalOverVideo,
} from "../../../../components/Notification/StripOverVideo";
import Portal from "../../../../components/Portal/Portal";
import { VideoError } from "./Video";
import WorkOutCompleted from "./WorkOutCompleted";
import Routes from "../../../../components/Router/Routes";
import logger from "../../../../utils/logger";
import { VideoPlayer as JwPlayer } from "../../../../components/VideoPlayer/index";
import { getPosterSize } from "../../../../utils/utilityfunctions";
class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.elapsedTimer = null;
    this.state = {
      elapsedTime: 0,
      videoPlaying: false,
      loading: false,
      video: this.props,
      player: null,
      finished: false,
      showBottomBar: false,
      paused: true,

      workOutCompleted: false,
      VideoError: false,
      showLeaveBar: false,
      isLeavingSelected: false,
      isIOS: false,
      isFullScreen: false,
      playerMounted: true,
      hideStripComponent: true,
      isClassStarted: false,
      initPlayer: false,
      showLeaveIcon: false,
    };
    this._isMounted = false;
    this._isRemoved = false;
  }
  // not in use

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.videoProps !== prevProps.videoProps &&
      this.props?.ClassDetails?.id !== prevProps?.ClassDetails?.id
    ) {
      if (
        !this.state.workOutCompleted &&
        (!this.state.isLeavingSelected || this.state.paused)
      ) {
        if (this.state.elapsedTime > 0 && this.state.elapsedTime < 60) {
          this.props.trackAndLogClass(
            false,
            true,
            this.state.elapsedTime,
            prevProps.props
          );
        } else if (this.state.elapsedTime > 60) {
          this.props.trackAndLogClass(
            true,
            true,
            this.state.elapsedTime,
            prevProps.props
          );
        }
        this._isRemoved = true;
        this.player.remove();
        this.setState({
          isLeavingSelected: false,
          workOutCompleted: false,
          loading: false,
          playerMounted: false,
          initPlayer: true,
          elapsedTime: 0,
        });
      } else if (this.props.videoProps !== prevProps.videoProps) {
        if (!this.state.isClassStarted && this.props.isPlayerOn) {
          this.props.classClosed();
          this.setState({
            isLeavingSelected: false,
            workOutCompleted: false,
            loading: false,
            playerMounted: false,
            initPlayer: true,
            elapsedTime: 0,
          });
        }
      }
    } else if (this.props.videoProps !== prevProps.videoProps) {
      this.props.classClosed();
      this.setState({
        isLeavingSelected: false,
        workOutCompleted: false,
        loading: false,
        playerMounted: false,
        elapsedTime: 0,
        initPlayer: true,
      });
    }
  }

  componentWillUnmount() {
    this.elapsedTimer = null;
    if (
      !this.state.workOutCompleted &&
      (!this.state.isLeavingSelected || this.state.paused)
    ) {
      if (this.state.elapsedTime > 0 && this.state.elapsedTime < 60) {
        this.props.trackAndLogClass(
          false,
          true,
          this.state.elapsedTime,
          this.props.props
        );
      } else if (this.state.elapsedTime > 60) {
        this.props.trackAndLogClass(
          true,
          true,
          this.state.elapsedTime,
          this.props.props
        );
      }
    }

    this._isMounted = false;
    this._isRemoved = false;
    if (this.player) {
      this.player.remove();
      this.player.getContainer().remove();
    }
  }
  componentDidMount() {
    this._isMounted = true;
    var iOS =
      !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    this.setState({ isIOS: iOS });
    this.setState({ showLeaveBar: true });
    if (this.props.videoError) {
      this.setState({ VideoError: true });
    }
  }
  startVideo = (video, jPlayer) => {
    this.player = jPlayer;
    this.setState({ loading: false, initPlayer: false, elapsedTime: 0 });
    if (video != null && this.player !== null) {
      let videoDiv = document.getElementById("video-wrapper");
      const videoEle = this.player.getContainer();
      videoEle.classList.add("vjs-tech");
      videoDiv.classList.add("vjs-has-started");
      this.setState({
        playerMounted: true,
        finished: false,
        loading: false,
        paused: this.props.paused,
      });
      // When the screen load in the play back screen in queue check the value it's fullscreen on not
      this.player.on("beforePlay", () => {
        if (this.props.PlaylistPlayer == "PlaylistPlayer") {
          const temp = this.props.playerWasOnFullScreen;
          if (temp === true) {
            this.player.setFullscreen(temp);
          }
        }
      });

      // In the video Player when user click on the full screen event calls
      this.player.on("fullscreen", (event) => {
        if (
          this.props.PlaylistPlayer == "PlaylistPlayer" &&
          !(this.player.getCurrentTime() == this.player.getDuration())
        ) {
          this.props.setPlayerWasOnFullScreen(this.player.getFullscreen());
        }
      });

      this.player.on("play", () => {
        logger("Player started");
        this.setState({ paused: false, hideStripComponent: false });
        if (this.state.isClassStarted === false) {
          this.props.classStarted && this.props.classStarted();
          this.setState({ isClassStarted: true });
        }
        if (this.player.getState() === "playing") {
          this.setState({
            paused: false,
            isLeavingSelected: false,
            VideoError: false,
          });
          var starter = this.state.elapsedTime;
          this.elapsedTimer = setInterval(() => {
            starter++;
            this.setState({ elapsedTime: starter });
          }, 1000);
        }
      });
      this.player.on("seeked", (e) => {
        clearInterval(this.elapsedTimer);
      });

      // let elapsedTimer = 0;

      this.player.on("remove", (e) => {
        if (this._isRemoved === false) {
          this.props.sendPlayDurationToLocalytics(this.state.elapsedTime);
          this.props.classClosed();

          logger(this.state.elapsedTime);
          this.props.elapsedTimeLiftstateUp(this.state.elapsedTime);
          this.setState({ elapsedTime: 0 });
        } else {
        }
      });
      this.player.on("pause", (e) => {
        clearInterval(this.elapsedTimer);
        this.elapsedTimer = null;
        this.props.elapsedTimeLiftstateUp(this.state.elapsedTime);
        if (!this.state.VideoError) {
          this.setState({
            paused: true,
          });
        }
      });

      this.player.on("complete", (e) => {
        //When user come from playlist set the current status of screen
        if (this.props.PlaylistPlayer == "PlaylistPlayer") {
          this.props.setPlayerWasOnFullScreen(this.player.getFullscreen());
        }
        if (
          document.fullscreenElement !== undefined &&
          document.fullscreenElement !== null
        ) {
          if (this.props.PlaylistPlayer == "PlaylistPlayer") {
            this.player.setFullscreen(false);
          } else {
            this.player.exitFullscreen();
          }
        }

        this.setState({
          paused: false,
          isLeavingSelected:
            this.props.PlaylistPlayer == "PlaylistPlayer" ? true : false,
          workOutCompleted:
            this.props.PlaylistPlayer == "PlaylistPlayer" ? false : true,
          VideoError: false,
        });
        clearInterval(this.elapsedTimer);
        this.elapsedTimer = null;
        this.props.nextClassPlay && this.props.nextClassPlay(this.props);
        this.props.trackAndLogClass(true, true, this.state.elapsedTime);
        this.props.elapsedTimeLiftstateUp(this.state.elapsedTime);
      });

      this.player.on("error", (e) => {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.VideoError = true;
      });
    }
  };

  resumeHandler = () => {
    this.player.play();
    this.setState({
      paused: false,
    });
  };

  leavingClassSelected = () => {
    if (
      document.fullscreenElement !== undefined &&
      document.fullscreenElement !== null
    ) {
      this.setState({ isFullScreen: true });
      document.exitFullscreen();
    }
    this.player.pause();
    this.setState({
      isLeavingSelected: true,
    });
  };

  closeHandler = () => {
    this.props.navigate(Routes.homePage);
  };

  discardHandler = () => {
    // logger("discardHandler")
    // if (this.state.isClassStarted){
    //   this.props.trackAndLogClass(false, true);
    // }

    // this.setState({
    //   elapsedTime: 0
    // });

    // RRneed to check if user came from deep links
    // use ==> this.props.history.push({ pathname: "/ondemand" });
    //otherwise
    if (
      this.props?.location?.state !== null &&
      this.props?.location?.state !== undefined
    ) {
      if (this.props?.location?.state?.from === "CHANNEL") {
        this.props.clearClassData();
      }
      window.history.back();
    } else {
      this.props.navigate(Routes.homePage);
    }
  };

  logClassHandler = () => {
    logger("logClassHandler");
    this.props.trackAndLogClass(true, true);
    this.setState({
      paused: false,
      isLeavingSelected: false,
      workOutCompleted: true,
    });
  };

  workOutCompleteHandler = () => {
    if (
      this.props?.location?.state !== null &&
      this.props?.location?.state !== undefined
    ) {
      this.props.navigate(-1);
    } else {
      this.props.navigate(Routes.homePage);
    }
  };

  showleaveBarMethod = () => {
    this.setState({ showLeaveBar: true });
  };

  showLeaveIcon = () => {
    this.setState({ showLeaveIcon: true });
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      this.setState({ showLeaveIcon: false });
    }, 3000);
  };

  hideleaveIcon = () => {
    this.setState({ showLeaveIcon: false });
  };

  hideleaveBarMethod = () => {
    if (this.state.paused) {
      this.setState({ showLeaveBar: false });
    }
  };
  ClassDurationTimeBand = (min) => {
    if (min <= 60) {
      //let min = sec / 60;
      let nearestInt = Math.ceil(min / 10);

      let maxValue = nearestInt * 10;

      return `${maxValue === 10 ? 0 : maxValue - 9}-${maxValue}`;
    } else if (min > 60) {
      return "61+";
    }
  };
  aroundTimeBand = (sec) => {
    if (sec <= 60) {
      return "0-1";
    } else if (sec > 60 && sec <= 300) {
      return "1-5";
    } else if (sec > 300 && sec <= 600) {
      return "6-10";
    } else if (sec > 600 && sec <= 3660) {
      let min = sec / 60;
      let nearestInt = Math.ceil(min / 10);

      let maxValue = nearestInt * 10;

      return `${maxValue - 9}-${maxValue}`;
    } else if (sec > 3660) {
      return "61+";
    }
  };

  render() {
    return (
      <div onMouseMove={this.showLeaveIcon}>
        <div id="video-wrapper" className="video-wrapper">
          <div className="livePlayer ClassPlayer video-js">
            <JwPlayer
              autostart={this.props.autostart}
              startVideo={this.startVideo}
              file={this.props.videoProps.url}
              image={`${this.props.videoProps.thumbnail}?width=${
                getPosterSize("playerDetail").playerWidth
              }`}
              eventId={
                this.props?.ClassDetails?.id ?? this.props?.videoProps?.classId
              }
              hideFullScreen={this.props.hideFullScreen}
              initPlayer={this.state.initPlayer}
            />
          </div>
          {/* <div data-vjs-player key={this.props.videoProps.url}>
            <video
              controls
              preload='none'
              poster={this.props.videoProps.thumbnail}
              playsInline
              ref={this.startVideo}
              className="ClassPlayer video-js vjs-default-skin vjs-matrix"
              onMouseMove={this.showleaveBarMethod}
              onLoadStart={this.showleaveBarMethod}
            >
              <source
                src={this.props.videoProps.url}
                type={this.props.videoProps.type ? this.props.videoProps.type : "application/x-mpegURL"}
              />
            </video>
          </div> */}

          {/* NOTE : The below code is for showing the leave button once player is mounted */}
          {/* {this.state.playerMounted && <Portal>
            <StripComponent
              isFullScreen={this.props.isFullScreen}
              showleaveBarMethod={this.showleaveBarMethod}
              leavingClassSelected={this.leavingClassSelected}
              videotitle={this.props.videoProps.title}
            />
          </Portal>} */}

          {this.state.showLeaveIcon === true && this.state.paused === false && (
            <Portal>
              <StripComponent
                isFullScreen={this.props.isFullScreen}
                showleaveBarMethod={this.showleaveBarMethod}
                leavingClassSelected={this.leavingClassSelected}
                videotitle={this.props.videoProps.title}
              />
            </Portal>
          )}
          {!this.state.hideStripComponent &&
            this.state.paused === true &&
            this.state.isLeavingSelected === false && (
              <Portal>
                <StripComponent
                  isFullScreen={this.props.isFullScreen}
                  showleaveBarMethod={this.showleaveBarMethod}
                  leavingClassSelected={this.leavingClassSelected}
                  videotitle={this.props.videoProps.title}
                />
              </Portal>
            )}

          {this.state.paused && this.state.isLeavingSelected && (
            <Portal>
              <ModalOverVideo
                resumeHandler={this.resumeHandler}
                elapsedTime={this.state.elapsedTime}
                discardHandler={this.discardHandler}
                logClassHandler={this.logClassHandler}
              />
            </Portal>
          )}
          <div className="player-spinner">
            <Fade
              in={this.state.loading}
              style={{
                transitionDelay: this.props.loading ? "800ms" : "0ms",
                backgroundColor: "#fff",
              }}
              unmountOnExit
            >
              <Spinner />
            </Fade>
          </div>
          {this.state.VideoError && (
            <VideoError
              navigate={this.props.navigate}
              videoError={this.props.videoError}
            />
          )}
          {this.state.workOutCompleted && (
            <Portal>
              <WorkOutCompleted
                videotitle={this.props.videoProps.title}
                elapsedTime={this.state.elapsedTime}
                workOutCompleteHandler={this.workOutCompleteHandler}
              />
            </Portal>
          )}
        </div>
      </div>
    );
  }
}

export default VideoPlayer;
