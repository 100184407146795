import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { Translate } from "../../../../utils/Translate";
import Routes from "../../../../components/Router/Routes";
import logger from "../../../../utils/logger";
import CloseGreen from "CommonImages/closeGreen";
import { useNavigate } from "react-router";
import config from "../../../../assets/config.json";
const BrowseCollectionsList = (props) => {
  const navigate = useNavigate();
  function openCollectionPages(item) {
    logger(props.location.state);
    navigate(Routes.collectionViewRoute + item.collectionTag, {
      state: { selectedCollection: item.collectionName },
    });
  }
  const closehandler = () => {
    navigate(-1);
  };
  return (
    <Fragment>
      <div className="page-container m-t-15">
        <img
          className="CloseButton customClose btn-default hide-xs"
          src={require("../../../../CommonImages/close.png")}
          onClick={closehandler}
          alt="close"
        />
      </div>
      <div className="page-container clear">
        <div className="browse-collection-menu clear">
          <div className="btn-container" onClick={closehandler}>
            <CloseGreen color={config.primaryColor} />
          </div>
          <Typography variant="h1">
            {Translate({ id: "ClassListViews.Collections" })}
          </Typography>
          <ul>
            {props.location.state &&
              props.location.state.collectionArray &&
              props.location.state.collectionArray.map((item, index) => (
                <li onClick={() => openCollectionPages(item)}>
                  {item.collectionName}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default BrowseCollectionsList;
